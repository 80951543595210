import useStyles from './styles'
import React from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
    Avatar, Button,
    Grid,
    IconButton,
    Paper,
    Slider,
    SwipeableDrawer,
    Switch,
    Typography
} from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import {styled} from "@mui/material/styles"
import {grey} from "@mui/material/colors"
import Box from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"
import {CloseOutlined} from "@mui/icons-material"
import useFilterOptions from "../../../../hooks/useFilterOptions"
import { useTranslation } from 'react-i18next'
import useFilterChanged from "../../../../hooks/useFilterChanged"
import useToggleFilter from "../../../../hooks/useToggleFilter"
import config from "../../../../config"

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window
}

const Root = styled('div')(({theme}) => ({
    height: '100%',
    backgroundColor: theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default
}))

const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}))

const Puller = styled(Box)(({theme}) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}))

function valuetext(value: number) {
    return `${value}°C`
}

const FilterLocations = (props: Props) => {
    const classes = useStyles()
    const {window} = props
    const isDesktop = useMediaQuery('(min-width: 900px)')
    const { t } = useTranslation()
    const drawerBleeding = isDesktop ? 0 : 40

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined
    const {filterOptions, setFilterOptions, resetToDefault, filterHasChanges} = useFilterOptions()
    const {filterChanged, setFilterChanged} = useFilterChanged()
    const {toggleFilter, setToggleFilter} = useToggleFilter()

    return (
        toggleFilter && (
            <div className={isDesktop ? classes.filterWrapperDesktop : classes.filterWrapper}>
                <Paper elevation={3} square={false} className={classes.buttonWrapper}>
                    <IconButton edge="start"
                                color="inherit"
                                onClick={() => {
                                    setToggleFilter(true)
                                    setFilterChanged(filterChanged+1)
                                }}
                                aria-label="close"
                                className={classes.button}
                    >
                        {
                            filterHasChanges() && (
                                <div className={classes.dot}/>
                            )
                        }
                        <Avatar sx={{bgcolor: 'white'}}>
                            <FilterListIcon className={classes.image}/>
                        </Avatar>
                    </IconButton>
                </Paper>
                <Root>
                    <CssBaseline/>
                    <SwipeableDrawer
                        className={isDesktop ? classes.filterDrawerDesktop : classes.filterDrawerMobile}
                        container={container}
                        anchor={isDesktop ? 'left': 'bottom'}
                        open={toggleFilter}
                        onOpen={() => {}}
                        onClose={() => {
                            setToggleFilter(false)
                            setFilterChanged(filterChanged+1)
                        }}
                        swipeAreaWidth={drawerBleeding}
                        disableSwipeToOpen={false}
                        ModalProps={{
                            keepMounted: false,
                        }}
                    >
                        <StyledBox
                            sx={{
                                position: 'absolute',
                                top: -drawerBleeding,
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                visibility: 'visible',
                                right: 0,
                                left: 0
                            }}
                        >
                            {
                                !isDesktop && (
                                    <Puller/>
                                )
                            }
                            <div className={classes.topSection}>
                                <div className={classes.topLeftSection}>
                                    {
                                        isDesktop && (
                                            <Typography variant={'h6'}>
                                                {t('locationMap.filterOptions')}
                                            </Typography>
                                        )
                                    }
                                </div>
                                <div className={classes.topRightSection}>
                                    <CloseOutlined onClick={() => {
                                        setToggleFilter(false)
                                        setFilterChanged(filterChanged+1)
                                    }}/>
                                </div>
                            </div>
                        </StyledBox>

                        {
                            filterOptions && (
                                <Grid container className={classes.drawerContainer}>
                                    {
                                        !isDesktop && (
                                            <Grid item xs={12} className={classes.titleWrapper}>
                                                <Typography variant={'h6'}>
                                                    {t('locationMap.filterOptions')}
                                                </Typography>
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={12}>
                                        <section className={classes.section}>
                                            <Typography variant={'caption'}>
                                                {t('locationMap.maxPower')}
                                            </Typography>
                                        </section>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.maxPower')}
                                        </Grid>
                                        <Grid item xs={6} className={classes.maxPowerValue}>
                                            <Typography variant={'subtitle2'}>
                                                {filterOptions['max_power'][0]} - {filterOptions['max_power'][1]}{filterOptions['max_power'][1] === config.filterOptions.MAX_POWER ? '+' : ''} kW
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={1}/>
                                        <Grid item xs={10}>
                                            <Box className={classes.maxPowerSlider}>
                                                <Slider value={filterOptions['max_power']}
                                                        onChange={(event, newValue: number[]) => {
                                                            setFilterOptions('max_power', newValue)
                                                        }}
                                                        valueLabelDisplay="auto"
                                                        getAriaValueText={valuetext}
                                                        color="secondary"
                                                        min={1}
                                                        max={config.filterOptions.MAX_POWER}
                                                        disableSwap={true}
                                                        data-testid="maxPowerFilter"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <section className={classes.section}>
                                            <Typography variant={'caption'}>
                                                {t('locationMap.connectorTypes')}
                                            </Typography>
                                        </section>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.ccsPlug')}
                                        </Grid>
                                        <Grid className={classes.switchWrapper} item xs={6}>
                                            <Switch color="secondary"
                                                    checked={filterOptions['ccs_plug']}
                                                    onChange={(event, checked) => {
                                                        setFilterOptions('ccs_plug', checked)
                                                    }}
                                                    data-testid="ccsPlug"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.chaDeMoPlug')}
                                        </Grid>
                                        <Grid className={classes.switchWrapper} item xs={6}>
                                            <Switch color="secondary"
                                                    checked={filterOptions['chademo_plug']}
                                                    onChange={(event, checked) => {
                                                        setFilterOptions('chademo_plug', checked)
                                                    }}
                                                    data-testid="chademoPlug"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.type2Socket')}
                                        </Grid>
                                        <Grid className={classes.switchWrapper} item xs={6}>
                                            <Switch color="secondary"
                                                    checked={filterOptions['type2_socket']}
                                                    onChange={(event, checked) => {
                                                        setFilterOptions('type2_socket', checked)
                                                    }}
                                                    data-testid="type2Socket"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.type2Plug')}
                                        </Grid>
                                        <Grid className={classes.switchWrapper} item xs={6}>
                                            <Switch color="secondary"
                                                    checked={filterOptions['type2_plug']}
                                                    onChange={(event, checked) => {
                                                        setFilterOptions('type2_plug', checked)
                                                    }}
                                                    data-testid="type2Plug"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <section className={classes.section}>
                                            <Typography variant={'caption'}>
                                                {t('locationMap.others')}
                                            </Typography>
                                        </section>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.freeOfChargeOnly')}
                                        </Grid>
                                        <Grid className={classes.switchWrapper} item xs={6}>
                                            <Switch color="secondary"
                                                    checked={filterOptions['free_of_charge']}
                                                    onChange={(event, checked) => {
                                                        setFilterOptions('free_of_charge', checked)
                                                    }}
                                                    data-testid="freeOfCharge"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.showRoamingStations')}
                                        </Grid>
                                        <Grid className={classes.switchWrapper} item xs={6}>
                                            <Switch color="secondary"
                                                    checked={filterOptions['roaming']}
                                                    onChange={(event, checked) => {
                                                        setFilterOptions('roaming', checked)
                                                    }}
                                                    data-testid="roaming"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.filterOptionWrapper}>
                                        <Grid item xs={6}>
                                            {t('locationMap.showPartnerStations')}
                                        </Grid>
                                        <Grid className={classes.switchWrapper} item xs={6}>
                                            <Switch color="secondary"
                                                    checked={filterOptions['show_partners_station']}
                                                    onChange={(event, checked) => {
                                                        setFilterOptions('show_partners_station', checked)
                                                    }}
                                                    data-testid="partnerStations"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className={isDesktop ? classes.buttonsWrapperDesktop : classes.buttonsWrapper}>
                                        <Box sx={{'& button': {m: 1}}}>
                                            <div className={classes.filterButtonWrapper}>
                                                <Button fullWidth={true} variant="outlined" color="secondary"
                                                        onClick={() => {
                                                            resetToDefault()
                                                        }}
                                                        data-testid="resetToDefaults"
                                                >
                                                    <Typography variant={'subtitle2'}>
                                                        {t('locationMap.resetToDefaults')}
                                                    </Typography>
                                                </Button>
                                            </div>
                                            <div className={classes.filterButtonWrapper}>
                                                <Button fullWidth={true}
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            setToggleFilter(false)
                                                            setFilterChanged(filterChanged+1)
                                                        }}
                                                        data-testid="applyAndClose"
                                                >
                                                    <Typography variant={'subtitle2'}>
                                                        {t('locationMap.applyAndClose')}
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </SwipeableDrawer>
                </Root>
            </div>
        )
    )
}

export default FilterLocations